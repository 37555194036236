import React, {useEffect, useState} from "react";
import { Text, ThemeContext, Box} from "grommet";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Curve, ReferenceLine} from "recharts";
import { normalizeColor } from "grommet/utils";
import Loading from "./Loading";
const AveragePaymentPeriodChart = ({data, change, netTerms}) => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [isSinglePoint, setIsSinglePoint] = useState(false);

    // Check if data is empty
    useEffect(()=>{
        if(!data){return;}
        const value = data.reduce((a, b) => a + (b.number_of_days || 0), 0);
        setIsEmpty(value == 0);
        setIsSinglePoint(data[0].number_of_days == value);
    },[data])

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length > 0) {
            return (
                <Box background="background-front" border round="xsmall" pad="small" elevation="xsmall">
                    <Text size="small" color="text-weak">{payload[0].payload?.month}</Text>
                    <Box gap="xsmall">
                        <Text size="large" weight={400}>{payload[0].value} days</Text>
                    </Box>
                </Box>
            );
        }
        return null;
    };
    if(!data){
        return(<Loading/>);
    }
    const lineColor = (change > 0) ? "status-critical" : "barchart-primary";
    const graphColor = isEmpty ? "text-weak" : "text"
    const CustomDot = ({cx, cy, color}) => {
        const points = [{x: 65, y: cy}, {x: cx*2, y: cy}]
        return (
            <circle stroke={color} fill={color} r={3} type="linear" cx={cx} cy={cy} />
        );
    }

    return(
        <Box>
            <ThemeContext.Consumer>
                { (theme) => (
                    <ResponsiveContainer width="100%" height={250} id="chart-container">
                        <LineChart width={3} pad={5} data={data} >
                            <ReferenceLine 
                                y={netTerms} 
                                label={{value: `Net ${netTerms}`, position: 'bottom', fill: "grey"}} 
                                stroke="grey" 
                                strokeDasharray="3 3"/>
                            <XAxis
                                dataKey="display_month_string"
                                padding={{ left: 35, right: 40 }}
                                tick={{fontSize: 17, fill: normalizeColor(theme.global.colors[graphColor], theme)}}
                            />
                            <YAxis
                                tick={{fontSize: 17, fill: normalizeColor(theme.global.colors[graphColor], theme)}}
                                dy={-5} 
                                unit=" days" 
                                width={65}
                                type="number" 
                                tickLine={false} 
                                axisLine={false} 
                                allowDecimals={false} 
                                domain={[0, 'auto']}
                            />
                            <CartesianGrid vertical={false} strokeDasharray="1 0"/>
                            <Tooltip
                                cursor={{ fill: normalizeColor(theme.global.colors.accent, theme) }}
                                content={renderTooltip}
                            />
                            <Line dataKey="number_of_days" dot={(isSinglePoint) ? true : false} connectNulls={true} strokeWidth={(isEmpty ? 0 : 3)} stroke={normalizeColor(theme.global.colors[lineColor],theme)}/>
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </ThemeContext.Consumer>
        </Box>
    )
};

export default AveragePaymentPeriodChart;
