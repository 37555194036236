import {Box, ResponsiveContext, Text} from "grommet";
import React, { useContext } from "react";
import FontAwesomeIcon from "./FontAwesomeIcon";
import {faCircleExclamation as IconAlertCircle, faAngleRight as IconArrowRight} from '@fortawesome/pro-light-svg-icons'
import ConditionalWrapper from "./ConditionalWrapper";
import { handleErrors } from "../utils/errors";
import Button from "./Button";
import { snakeCase } from "../utils/string";
import { useRouter } from "next/router";

const NotificationBanner = ({notification, onDismiss, containerProps, ctaContainerProps, messageProps, showDismiss=true, showCTA=true}) => {
    let color;
    switch(notification.priority){
    case 'ok':
        color = 'status-ok';
        break;
    case 'info':
        color = 'status-info';
        break;
    case 'warning':
        color = 'status-warning';
        break;
    case 'critical':
        color = 'status-critical';
        break;
    default:
        color = 'status-ok';
    }

    let icon;
    switch(notification.icon){
    default:
        icon = IconAlertCircle;
    }
    const router = useRouter();
    const size = useContext(ResponsiveContext);
    const isMobile = size === "small";

    const onClick = () => {
        let isExternalLink = false;
        try { // in case notification.action is not a valid url
            isExternalLink = window.location.hostname !== new URL(notification.action).hostname;
        } catch {}
        isExternalLink ? window.open(notification.action, '_blank') : router.push(notification.action);
    }

    const markNotificationAsRead = (notificationId) =>{
        fetch("/api/notifications/mark_as_read", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: notificationId
            })
        }).then(handleErrors).then((data)=>{
            onDismiss && onDismiss();
        })
    }

    return (
        <ConditionalWrapper 
            wrapperIfTrue={(children) => (
                <Button
                    event={{section: 'notification_banner', element: 'banner', description: snakeCase(notification?.message)}} 
                    onClick={onClick}
                >
                    {children}
                </Button>
            )}
            wrapperIfFalse={(children) => (<>{children}</>)}
            condition={isMobile && onClick}
        >
            <Box margin={{bottom: "small"}} elevation="small" {...containerProps}>
                <Box
                    pad={{ horizontal: "small", vertical: "small" }}
                    align="center"
                    direction="row"
                    gap={"small"}
                    width={{ max: "100%", min: "small" }}
                    elevation={"small"}
                    justify="between"
                    border={[
                        { side: "left", size: "5px", color: color },
                        { side: "horizontal", size: "1px" },
                        { side: "right", size: "1px" },
                    ]}
                >
                    <Box gridArea="message" pad="xsmall" direction="row" gap={isMobile ? "small" : "small"}>
                        <FontAwesomeIcon
                            SelectedIcon={icon}
                            color={color}
                            customSize="24px"
                            border={{
                                color,
                                size: "medium",
                            }}
                            iconContainerProps={{
                                width: {min: "24px"}
                                
                            }}
                        />
                        <Text wordBreak="break-word" weight={450} {...messageProps}>
                            {notification.message}
                        </Text>
                    </Box>
                    {/* CTA */}
                    {(showDismiss || showCTA) && (
                        <Box
                            margin={{ right: isMobile ? "xsmall" : "medium" }}
                            gap="xxsmall"
                            direction="row"
                        >
                            {showDismiss && !isMobile && (
                                <Button
                                    event={{section: 'notification_banner', element: 'dismiss', description: snakeCase(notification?.message)}} 
                                    secondary={isMobile}
                                    label={<Text weight={400}>Dismiss</Text>}
                                    onClick={() => {
                                        markNotificationAsRead(notification.id);
                                    }}
                                />
                            )}
                            {showCTA && !isMobile && (
                                <Button
                                    event={{section: 'notification_banner', element: 'review', description: snakeCase(notification?.message)}} 
                                    secondary
                                    label={
                                        <Box direction="row" align="center" justify="between" gap="xsmall">
                                            <Text weight={450}>Review</Text>
                                            <FontAwesomeIcon SelectedIcon={IconArrowRight} size="xsmall"/>
                                        </Box>
                                    }
                                    onClick={onClick}
                                />
                            )}
                            {showCTA && isMobile && (
                                <Box>
                                    <FontAwesomeIcon SelectedIcon={IconArrowRight} size="xxsmall"/>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </ConditionalWrapper>
    );
};

export default NotificationBanner;
