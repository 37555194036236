import React from "react";

const Shopify = ({ size, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 50 50" width={`${size}px`} height={`${size}px`}>
        <path d="M40.295,11.284c-0.011-0.1-0.037-0.198-0.077-0.291c-0.196-0.45-0.55-0.773-1.023-0.936 c-0.092-0.031-0.202-0.05-0.299-0.054c-0.222-0.009-2.194-0.161-3.033-0.227c-0.686-0.701-2.003-2.043-2.346-2.352 c-0.011-0.011-0.024-0.018-0.035-0.028l-1.846,40.411l12.581-2.784c0.512-0.113,0.849-0.602,0.774-1.119 C43.39,32.807,40.35,11.702,40.295,11.284z" />
        <path d="M31.488,7.197l-1.112,0.362c-0.663-2.444-1.902-3.982-3.536-4.331c-0.495-0.108-0.974-0.085-1.438,0.032 c-0.557-0.588-1.198-0.993-1.929-1.15c-3.363-0.718-7.028,3.646-8.944,10.611l-5.645,1.839c-0.367,0.12-0.633,0.44-0.683,0.823 L4.756,42.193c-0.067,0.525,0.285,1.012,0.806,1.11l24.068,4.58L31.488,7.197z M24.755,5.918c0.365,0.849,0.631,1.948,0.766,3.223 l-3.73,1.215C22.804,8.206,23.847,6.73,24.755,5.918z M23.056,4.066c0.171,0.037,0.336,0.114,0.496,0.216 c-1.985,1.697-3.502,4.916-4.304,6.903l-2.401,0.782C18.591,6.607,21.333,3.704,23.056,4.066z M26.143,19.282l-1.512,4.66 c-0.046,0.142-0.152,0.255-0.29,0.31c-0.14,0.058-0.295,0.046-0.425-0.024c-0.013-0.007-1.285-0.688-2.759-0.688 c-1.783,0-2.05,0.912-2.05,1.455c0,0.589,0.774,1.171,1.671,1.844c1.587,1.192,3.76,2.825,3.76,6.101 c0,4.104-2.556,6.972-6.214,6.972c-4.16,0-6.255-2.592-6.342-2.702c-0.101-0.127-0.133-0.296-0.087-0.452l1.063-3.573 c0.049-0.163,0.178-0.291,0.342-0.338c0.163-0.044,0.341-0.006,0.469,0.105c0.02,0.018,1.958,1.71,3.493,1.71 c0.893,0,1.129-0.716,1.129-1.094c0-0.98-0.717-1.576-1.625-2.331c-1.254-1.042-2.813-2.339-2.813-5.154 c0-3.881,2.705-8.058,8.646-8.058c0.04,0,0.08-0.001,0.12-0.001c2.064,0,3.161,0.648,3.208,0.677 C26.124,18.82,26.214,19.062,26.143,19.282z M27.453,8.511c-0.16-1.238-0.423-2.334-0.796-3.244 c0.768,0.317,1.414,1.35,1.819,2.911L27.453,8.511z" />
    </svg>
);

export default Shopify;
