import React, {useState} from "react";
import {Box, ResponsiveContext, Text} from "grommet";
import useSWR from "swr";
import {handleErrors} from "../utils/errors";
import GettingStarted, {mapOnboardingStatusToItem} from "../components/pages/dashboard/GettingStarted";
import DashboardComponent from "../components/pages/dashboard/Dashboard";
import AppLayout from "../components/layouts/AppLayout";
import UpdateShopifyTemplate from "../components/UpdateShopifyTemplate";
import Modal from "../components/Modal";
import {useSession} from "next-auth/react";
import MessageBanner from "../components/MessageBanner";
import FontAwesomeIcon from "../components/FontAwesomeIcon";
import {faAngleRight as IconArrowRight} from '@fortawesome/pro-light-svg-icons';
import {faCircleExclamation as IconAlertCircle} from '@fortawesome/pro-light-svg-icons';
import UserRoleAndTitleModal from "../components/pages/dashboard/UserRoleAndTitleModal";

const Dashboard = (props) => {
    const {data: session} = useSession();
    const {user, company} = session || {};
    const {first_name: name, role, title} = user || {};
    const [onboardingStatuses, setOnboardingStatuses] = useState(undefined);
    const [showUserRoleAndTitleModal, setShowUserRoleAndTitleModal] = useState(session && !(role && title))
    const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
    const [hasUpdatedShopifyEmailTemplate, setHasUpdatedShopifyEmailTemplate] = useState(false);
    const [openUpdateTemplateModal, setOpenUpdateTemplateModal] = useState(false);
    const fetcher = (url) => fetch(url)
        .then(handleErrors)
        .catch(props.showNotification);

    const checkOnboardingChecklist = (data) => {
        let whiteList = ["has_shopify_linked", "updated_shopify_template"];
        // If shopify is linked or order already exists (native mc), then skip check for quickbooks
        if(data["has_order_or_shopify"]){
            whiteList.push("has_quickbooks_linked");
        }
        whiteList.forEach(e => delete data[e]);
        return (Object.values(data).every(val => val))
    }
    // fetch status of onboarding action items
    useSWR(
        session ? `/api/company/onboarding` : undefined,
        fetcher,
        {
            revalidateOnFocus: false,
            onSuccess: data => {
                const items = mapOnboardingStatusToItem(data, company);
                // Ignore if shopify isn't linked
                if(data.has_shopify_linked){
                    let updated = !data.shopify_o_auths.some(obj => obj.email_template_updated === false)
                    setHasUpdatedShopifyEmailTemplate(updated);
                } else{
                    setHasUpdatedShopifyEmailTemplate(true);
                }
                setOnboardingStatuses(items);
                setIsOnboardingCompleted(checkOnboardingChecklist(data));
            }
        }
    );

    //TODO: enable when we want to use flagging
    // const {flag_key: flagEnabled} = getFeatureFlag('flag_key', session)
    // if flagEnabled () {
    // do something or render something
    // }

    return (
        <AppLayout
            title={`Welcome${name ? `, ${name}` : ''}!`}
            background="background-light">
            <Box width={{max: 'xlarge'}} pad={{top: 'small'}}>
                {showUserRoleAndTitleModal && <UserRoleAndTitleModal user={user} onClose={()=>setShowUserRoleAndTitleModal(false)}/>}
                { onboardingStatuses ? (
                    <>
                        {isOnboardingCompleted && !hasUpdatedShopifyEmailTemplate &&
                            <Box>
                                <MessageBanner
                                    message={(
                                        <Text weight={600}>
                                            Please update the Order email templates on Shopify
                                        </Text>
                                    )}
                                    color="status-warning"
                                    icon={IconAlertCircle}
                                    ctaText={(
                                        <Box direction="row" align="center" gap="xsmall">
                                            <Text>Update now</Text>
                                            <FontAwesomeIcon SelectedIcon={IconArrowRight} size="xsmall" />
                                        </Box>
                                    )}
                                    ctaProps={{margin: {horizontal: 'medium'}}}
                                    onClick={() => setOpenUpdateTemplateModal(true)}
                                    containerProps={{margin: {bottom: 'small'}}}
                                    fill="horizontal"
                                    iconProps={{customSize: '24px', border: false}}
                                    innerContainerProps={{ gap: 'small' }}
                                    size="small"
                                    iconWrapperProps={{width: {min: '24px'}}} />
                                { openUpdateTemplateModal &&
                                    (<Modal
                                        title="Update your Shopify Email Template"
                                        onClose={()=>{setOpenUpdateTemplateModal(false)}}
                                        containerProps={
                                            {
                                                width: "550px"
                                            }
                                        }
                                    >
                                        <UpdateShopifyTemplate pad="0px" background="foreground" onComplete={() => {setOpenUpdateTemplateModal(false)}}/>
                                    </Modal>)
                                }
                            </Box>
                        }
                        <ResponsiveContext.Consumer>
                            {(size) => (
                                isOnboardingCompleted ?
                                    <DashboardComponent size={size}/> :
                                    <GettingStarted items={onboardingStatuses} company={session ? company : {}} />
                            )}
                        </ResponsiveContext.Consumer>
                    </>
                ) : (<></>)}
            </Box>
        </AppLayout>
    );
};


export default Dashboard;

Dashboard.auth = true;
