import React from "react";
import { Text as GrommetText, ThemeContext, Box} from "grommet";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid} from "recharts";
import { formatCurrencySI, formatCurrency } from "../utils/numbers";
import { normalizeColor } from "grommet/utils";
const MonthlySalesChart = ({data, isLoading}) => {

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length > 0) {
            const fontWeight = 400;
            return (
                <Box background="background-front" border round="xsmall" pad="medium" elevation="xsmall">
                    <GrommetText size="small" color="text-weak">{payload[0].payload?.month}</GrommetText>
                    <Box gap="medium" direction="row">
                        <Box>
                            <GrommetText size="medium" weight={fontWeight}>Sales:</GrommetText>
                            <GrommetText size="medium" weight={fontWeight}>Payments:</GrommetText>
                        </Box>
                        <Box align="end">
                            <GrommetText size="medium" weight={fontWeight}>{formatCurrency(payload[0].value)}</GrommetText>
                            <GrommetText size="medium" weight={fontWeight}>{formatCurrency(payload[1].value)}</GrommetText>
                        </Box>
                    </Box>
                </Box>
            );
        }
        return null;
    };
    return(
        <ThemeContext.Consumer>
            { (theme) => (
                <Box gap="xsmall" skeleton={isLoading ? {animation: 'fadeIn'} : undefined }>
                    <Box justify="end" direction="row" gap="small" margin={{bottom: "xsmall"}}>
                        <Box direction="row" gap="small" align="center">
                            <Box round="xxsmall" background="barchart-primary" width="20px" height="20px" />
                            <GrommetText>Sales</GrommetText>
                        </Box>
                        <Box direction="row" gap="small" align="center">
                            <Box round="xxsmall" background="barchart-secondary" width="20px" height="20px" />
                            <GrommetText>Payments</GrommetText>
                        </Box>
                    </Box>
                    <ResponsiveContainer width="100%" height={250} id="chart-container">
                        <BarChart barSize={20} data={data} barGap={0}>
                            <XAxis 
                                dataKey="display_month_string" 
                                tick={{fontSize: 17, fill: normalizeColor(theme.global.colors["text"], theme)}}
                            />
                            <YAxis
                                tick={({ y, payload, tickFormatter })=>(
                                    <g transform={`translate(${0},${y+5})`}>
                                        <text x={0}
                                            textAnchor="start"
                                            fontSize={16}
                                            fill={normalizeColor(theme.global.colors["text"], theme)}>{tickFormatter(payload.value)}</text>
                                    </g>
                                )}
                                width={50}
                                dy={5}
                                tickFormatter={(item) => formatCurrencySI(item) }
                                axisLine={false}
                                tickLine={false}
                            />
                            <CartesianGrid vertical={false} strokeDasharray="5 0"/>
                            <Tooltip
                                cursor={{ fill: normalizeColor(theme.global.colors.accent, theme) }}
                                content={renderTooltip}
                            />
                            <Bar dataKey="sales_amount" fill={normalizeColor(theme.global.colors["barchart-primary"], theme)}/>
                            <Bar dataKey="payment_amount" fill={normalizeColor(theme.global.colors["barchart-secondary"], theme)} />

                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            )}
        </ThemeContext.Consumer>
    )
};

export default MonthlySalesChart;
