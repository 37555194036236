import {format, addDays} from 'date-fns'

// Check if this is a UTC date. If so, we should add offset of the local timezone, or the dates could be off by one day.
// e.g. "2024-01-21T00:00:00.000Z" will render as 2024-01-20 in EST, since UTC is ahead of EST
export const adjustTimeByTimeZoneIfUTC = (dt) => {
    if (!dt || !(dt instanceof Date)) return;
    if (dt.getUTCHours() === 0 && dt.getMinutes() === 0 && dt.getSeconds() === 0) {
        const timeZoneOffset = (new Date()).getTimezoneOffset() / 60;
        dt.setHours(dt.getHours() + timeZoneOffset);
    }
}

export const formatDate = (date, shortDate=false, ignoreSameYear=false) => {
    if (!date) return '';
    const dt = new Date(date);
    adjustTimeByTimeZoneIfUTC(dt);

    return dt
        .toLocaleDateString('en-US', shortDate ? {
            day: "2-digit",
            month: "2-digit",
            ...(ignoreSameYear && (dt.getFullYear() === getCurrentYear()) ?
                {} :
                { year: "numeric" }
            )
        } : {
            day: '2-digit',
            month: 'short',
            ...(ignoreSameYear && (dt.getFullYear() === getCurrentYear()) ?
                {} :
                { year: "numeric" }
            )
        });
}

export const getDateInLocalTime = (dateStr) => {
    const dt = dateStr ? new Date(dateStr) : new Date();
    adjustTimeByTimeZoneIfUTC(dt);
    return dt;
}

export const formatMonth = (date, shortDate=false) => {
    return date && new Date(date)
        .toLocaleDateString('en-US', shortDate ? {
            month: "2-digit",
            year: "numeric"
        } : {
            month: 'short',
            year: 'numeric',
        });
}

export const formatDateMonth = (date) => {
    if (!date) return '';
    return getDateInLocalTime(date)
        .toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
        });
}

export const formatDateUTC = (date) => {
    return date && getDateInLocalTime(date)
        .toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            timeZone: 'UTC'
        });
}

// DD/MM/YY
export const formatNumericDate = (date, sep) => {
    const formattedDate = date && getDateInLocalTime(date)
        .toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    return sep && formattedDate ? formattedDate.replace(/\//g, sep) : formattedDate;
}

export const formatddMMyyDate = (date) => format(date, 'ddMMMyy');

export const formatTime = (date) => date && new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
});

export const daysFromDate = (date, days) => {
    const parsedDate = new Date(date);
    const futureDate = parsedDate.setDate(parsedDate.getDate() + days);
    return date && new Date(futureDate).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

export const formatYear = (date) => date && new Date(date)
    .toLocaleDateString('en-US', {
        year: 'numeric',
    });

export const formatMonthYear = (date) => date && new Date(date)
    .toLocaleDateString('en-US', {
        month: '2-digit',
        year: 'numeric',
    });

export const isDateInRange = (date, range) => {
    if (!range || range.length !== 2) return false;
    return ((date >= range[0]) && (date <= range[1]));
}

export const getTodaysDate = () => {
    const now = new Date();
    return format(now, 'ddMMMyy');
}

export const dateToEpochSecond = (date) => date.getTime() / 1000;

export const getDiffDate = (date1, date2, isAbs=true, endOfDay=false) => {
    let dateA = date1;
    let dateB = date2;
    if(endOfDay){
        dateA = dateA.setHours(23, 59, 59, 999);
        dateB = dateB.setHours(23, 59, 59, 999);
    }
    const diff = parseInt((dateB - dateA) / (1000 * 60 * 60 * 24), 10);
    return isAbs ? Math.abs(diff) : diff;
}

export const getNDaysFrom = (date, days) => {
    return addDays(new Date(date), days);
}

export const getListofYears = () => {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    return range(currentYear, currentYear+10, 1);
}

export const getListOfMonths = (isFuture, year) => {
    const options = [
        {name: 'Jan', fullName: 'January', value: 1},
        {name: 'Feb', fullName: 'Feburary', value: 2},
        {name: 'Mar', fullName: 'March', value: 3},
        {name: 'Apr', fullName: 'April', value: 4},
        {name: 'May', fullName: 'May', value: 5},
        {name: 'Jun', fullName: 'June', value: 6},
        {name: 'Jul', fullName: 'July', value: 7},
        {name: 'Aug', fullName: 'August', value: 8},
        {name: 'Sep', fullName: 'September', value: 9},
        {name: 'Oct', fullName: 'October', value: 10},
        {name: 'Nov', fullName: 'November', value: 11},
        {name: 'Dec', fullName: 'December', value: 12},
    ];
    const currentYear = getCurrentYear();
    if (isFuture && (year == currentYear)) {
        const currentMonth = (new Date()).getMonth();
        return options.slice(currentMonth+1);
    }
    return options;
}

export const getCurrentYear = () => {
    return (new Date()).getFullYear();
}

export const getCurrentMonth = () => {
    return (new Date()).getMonth() + 1; // month starts from 0 in javascript
}

export const isLastDayOfMonth = (d) => {
    let test = new Date(d.getTime());
    test.setDate(test.getDate() + 1);
    return test.getDate() === 1;
}

export const nth = (dt) => {
    const d = dt.getDate();
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
    }
}


export const getToday = () => {
    const today = new Date();
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return todayWithoutTime;
}

export const getTomorrow = () => {
    const today = new Date();
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    return tomorrow;
}

export const addNDays = (date, n) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + n);
    return newDate;
}

export const isFutureDate = (date) => {
    if (!date) return false;
    return new Date(date) > (new Date());
}

export const isPastDate = (date) => {
    if (!date) return false;
    return new Date(date) < (new Date());
}

export const isToday = (date, isDateTimeObject = false) => {
    if (!date) return false;
    const today = new Date();
    const dt = isDateTimeObject ? date : new Date(date);
    return dt.getDate() == today.getDate() &&
        dt.getMonth() == today.getMonth() &&
        dt.getFullYear() == today.getFullYear();
}

// NOTE: MUST PASS 2 DATES..
export const getLaterDate = (date, dateTwo) => {
    const dateObj = new Date(date);
    const dateTwoObj = new Date(dateTwo);
    return dateObj >= dateTwoObj ? date : dateTwo
}
