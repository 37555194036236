export const AGING_BALANCE_GRAPH_COLORS = [
    'status-ok', 
    'status-warning-1', 
    'status-warning-2', 
    'status-critical-1', 
    'status-critical-2', 
];

export const AGING_BALANCE_GRAPH_COLOR_MAPPING = {
    "placeholder": "piechart-secondary",
    "Due": "status-ok",
    "1-30 days": "status-warning-1",
    "31-60 days": "status-warning-2",
    "61-90 days": "status-critical-1",
    "91+ days": "status-critical-2"
}
export const AGING_BALANCE_COLOR_MAP = {
    "<0": 'status-ok',
    "0+": 'status-warning-1',
    "30+": 'status-warning-2',
    "60+": 'status-critical-1',
    "90+": 'status-critical-2',
};

export const OVERDUE_COLOR_MAP = {
    "90+": "invoice-overdue-90+",
    "60+": "invoice-overdue-60+",
    "30+": "invoice-overdue-30+",
    "0+": "invoice-overdue",
    "<0": "invoice-not-yet-due",
};

export const FILTER_COLOR_MAP = {
    "91+ Days": "invoice-overdue-90+",
    "61-90 Days": "invoice-overdue-60+",
    "31-60 Days": "invoice-overdue-30+",
    "1-30 Days": "invoice-overdue",
    "Not Yet Due": "invoice-not-yet-due",
    "All Overdue": "status-warning-1",
    "Overdue": "status-warning-1",
    "Due": "status-warning-1"
};