import {Box, ResponsiveContext, Text, ThemeContext} from "grommet";
import React, { useContext } from "react";
import { normalizeColor } from "grommet/utils";
import FontAwesomeIcon from "./FontAwesomeIcon";
import {TextBasedIcon, ThickLeftBorderContainer} from "../utils/styled";
import ConditionalWrapper from "./ConditionalWrapper";
import {faAngleRight as IconArrowRight} from '@fortawesome/pro-light-svg-icons';
import Button from "./Button";
import { snakeCase } from "../utils/string";

const MessageBanner = ({
    header,
    message,
    icon,
    color,
    ctaText,
    onClick,
    containerProps,
    textIcon,
    iconProps = {},
    ctaProps,
    iconWrapperProps={},
    headerProps = {},
    innerContainerProps = {},
    primaryCta
}) => {
    const IconComponent = icon ? FontAwesomeIcon : TextBasedIcon;
    const size = useContext(ResponsiveContext);
    const isMobile = size === "small";
    return (
        <ThemeContext.Consumer>
            {(theme) => {
                const borderColor = normalizeColor(theme.global.colors.border, theme);
                const accentColor = normalizeColor(theme.global.colors[color], theme);
                return (
                    <ConditionalWrapper
                        wrapperIfTrue={(children) => (
                            <Button
                                event={{section: `${snakeCase(header || message)}_message_banner`, element: 'banner'}} 
                                onClick={onClick}
                            >
                                {children}
                            </Button>
                        )}
                        wrapperIfFalse={(children) => (<>{children}</>)}
                        condition={isMobile && onClick}
                    >
                        <ThickLeftBorderContainer
                            align="center"
                            gap="small"
                            justify="between"
                            pad="small"
                            border={{
                                color,
                                size: 'medium',
                                side: 'left'
                            }}
                            elevation="small"
                            margin={{vertical: 'xsmall'}}
                            width={{max: '100%', min: 'small'}}
                            borderColor={borderColor}
                            accentColor={accentColor}
                            direction="row"
                            background="background"
                            {...containerProps}
                        >
                            <Box
                                align={isMobile ? "start" : "center"}
                                direction="row"
                                gap={isMobile ? "medium" : "small"}
                                pad="xsmall"
                                {...innerContainerProps}>
                                <Box width={{min: '36px'}} {...iconWrapperProps}>
                                    <IconComponent
                                        SelectedIcon={icon}
                                        text={textIcon}
                                        color={color}
                                        size={isMobile ? "small" : "large"}
                                        border={{
                                            color,
                                            size: 'small'
                                        }}
                                        {...iconProps} />
                                </Box>
                                <Box direction="column">
                                    <Text size={isMobile ? "medium" : "large"} weight={600} {...headerProps}>{header}</Text>
                                    <Text>{message}</Text>
                                </Box>
                            </Box>
                            {ctaText && !isMobile && (
                                <Box margin={{horizontal: 'xsmall'}} flex={false} {...ctaProps}>
                                    <Button
                                        event={{section: `${snakeCase(header || message)}_message_banner`, element: 'primary_cta'}} 
                                        primary={!!primaryCta}
                                        secondary={!primaryCta}
                                        label={ctaText}
                                        onClick={onClick}
                                    />
                                </Box>
                            )}
                            {ctaText && isMobile && (
                                <Box>
                                    <FontAwesomeIcon SelectedIcon={IconArrowRight} size="xxsmall" />
                                </Box>
                            )}
                        </ThickLeftBorderContainer>
                    </ConditionalWrapper>
                );
            }}
        </ThemeContext.Consumer>
    );
}

export default MessageBanner;
