import React, { useState } from "react";
import {Box, Text, TextArea, Paragraph} from "grommet";
import { SHOPIFY_INVOICE_EMAIL_TEMPLATE } from "../constants/ShopifyTemplates";
import { handleErrors } from "../utils/errors";
import { mutate } from "swr";
import {
    faPaste as IconCopy
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "./FontAwesomeIcon";
import Button from "./Button";

const UpdateShopifyTemplate = (props) => {
    const [step, setStep] = useState(1)
    const [shop, setShop] = useState({})
    let frameHeight = 680
    let frameWidth = 650

    const copyLink = () => {
        const tempText = document.createElement('textarea');
        tempText.value = SHOPIFY_INVOICE_EMAIL_TEMPLATE;
        document.body.appendChild(tempText);
        tempText.select();
        document.execCommand('copy');
        document.body.removeChild(tempText);
    };


    const openShopifyEditTemplateLink = () => {
        fetch(`/api/settings/shopify`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(handleErrors).then((data) => {
            let first = data.find(obj => obj.email_template_updated === false);
            setShop(first)
            return first
        }).then((shop) => {
            window.open(`https://${shop.shop_domain}.myshopify.com/admin/email_templates/order_invoice/edit`, "oauthWindow",`menubar=0,resizable=1,width=${frameWidth},height=${frameHeight},left=${(window.screen.width-frameWidth)/2},top=${(window.screen.height-frameHeight)/2}`)
            next();
        })
    }
    const back = () => {
        setStep(step - 1);
    }
    const next = () => {
        setStep(step + 1);
    }

    const markAsCompleted = () => {
        fetch(`/api/settings/shopify/${shop.shop_domain}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email_template_updated: true
            })
        }).then(handleErrors).then((data) => {
            mutate(`/api/company/onboarding`);
            props.setOpen && props.setOpen(false);
            props.onComplete && props.onComplete();
        })
    }

    return (<Box
        background="foreground"
        align="center"
        justify="start"
        direction="row"
        gap="small"
        margin={props.margin || {top: 'xsmall'}}
        pad={props.pad || {left: '33px'}}
    >
        <Box flex={{grow:1, shrink: 1}} justify="around" gap="small">
            <Box justify="start" gap="small">
                <Text weight={600}>Step {step} of 3</Text>
                { (step === 1) &&
                    (
                        <Box gap="small">
                            <Text>Copy the following template</Text>
                            <Box direction="row" border="all" gap="xxsmall" height="medium">
                                <TextArea plain disabled={true} resize={false} fill value={SHOPIFY_INVOICE_EMAIL_TEMPLATE}/>
                                <Button 
                                    event={{section: 'update_shopify_template', element: 'copy_link', description: 'step_1'}}  
                                    border="none" 
                                    hoverIndicator 
                                    onClick={copyLink} 
                                    label={<FontAwesomeIcon SelectedIcon={IconCopy} size="small" />} />
                            </Box>
                            <Box direction="row" justify="end">
                                <Button 
                                    event={{section: 'update_shopify_template', element: 'next', description: 'step_1'}}  
                                    primary 
                                    hoverIndicator 
                                    onClick={next} 
                                    label={"Next"} />
                            </Box>
                        </Box>
                    )

                }
                { (step === 2) &&
                    (
                        <Box gap="small">
                            <Paragraph margin="none">Replace your Shopify "Order invoice" template with the template in your clipboard.</Paragraph>
                            <Box direction="row" justify="end" gap="small">
                                <Button 
                                    event={{section: 'update_shopify_template', element: 'back', description: 'step_2'}}  
                                    secondary 
                                    hoverIndicator 
                                    onClick={back} 
                                    label={"Back"} />
                                <Button 
                                    event={{section: 'update_shopify_template', element: 'open_template_in_shopify', description: 'step_2'}}  
                                    primary
                                    hoverIndicator 
                                    onClick={openShopifyEditTemplateLink} 
                                    label={"Open template in Shopify"} />
                            </Box>
                        </Box>
                    )

                }
                { (step === 3) &&
                    (
                        <Box gap="small">
                            <Text>Paste the template into the text box and hit save. Once saved, you may close the popup and mark as complete.</Text>
                            <Box direction="row" justify="end" gap="small">
                                <Button 
                                    event={{section: 'update_shopify_template', element: 'back', description: 'step_3'}}  
                                    secondary 
                                    hoverIndicator 
                                    onClick={() => {back()}} 
                                    label={"Back"} />
                                <Button 
                                    event={{section: 'update_shopify_template', element: 'mark_as_complete', description: 'step_3'}}  
                                    primary 
                                    hoverIndicator 
                                    onClick={markAsCompleted} 
                                    label={"Mark as complete"} />
                            </Box>
                        </Box>
                    )
                }
            </Box>
        </Box>
    </Box>)
}

export default UpdateShopifyTemplate;
