import React from "react";
import {Box, ThemeContext} from "grommet";
import {normalizeColor} from "grommet/utils";

const CustomIcon = ({ SelectedIcon, size = 'medium', color, bold=false, highlight=false, ...props }) => {
    let size_px;
    const stroke = bold ? 2.25 : 1.5;

    switch (size) {
    case 'xsmall':
        size_px = 16;
        break;
    case 'small':
        size_px = 18;
        break;
    case 'medium':
        size_px = 22;
        break;
    case 'large':
        size_px = 26;
        break;
    case 'xlarge':
        size_px = 36;
        break;
    case 'xxlarge':
        size_px = 64;
        break;
    default:
        size_px = 24;
        break;
    }

    return (
        <ThemeContext.Consumer>
            { (theme) => highlight ? 
                (<Box round="50%" height="35px" width="35px" background={highlight} pad={{top: '4px'}} margin={{vertical: 'xsmall'}} align="center">
                    <SelectedIcon 
                        size={26} 
                        color={normalizeColor(theme.global.colors[color], theme)}
                        stroke={stroke}
                        {...props} 
                    />
                </Box>) :
                (<SelectedIcon 
                    size={size_px} 
                    color={normalizeColor(theme.global.colors[color], theme)}
                    stroke={stroke}
                    {...props} 
                />)}
        </ThemeContext.Consumer>
    );
};

export default CustomIcon;
