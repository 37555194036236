import React, {useContext} from "react";
import Shopify from "./icons/Shopify";
import CustomIcon from "./CustomIcon";
import {Box, ResponsiveContext} from "grommet";
import {useRouter} from "next/router";
import {
    faFileCirclePlus as IconOrderCreate,
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "./FontAwesomeIcon";
import Button from "./Button";

const LinkOrCreateOrder = () => {
    const router = useRouter();
    const size = useContext(ResponsiveContext);

    const createOrder = () => {
        router.push('/draft-invoices/new/edit');
    }

    return (
        <Box fill="horizontal" direction={size === "small" ? "column" : "row"} align="center" gap="xsmall" pad={{left: '50px', right: "large"}}>
            <Button
                event={{section: 'link_or_create_order', element: 'link_shopify'}} 
                label="Link Shopify"
                onClick={() => {router.push('/settings/connections')}}
                icon={<Box pad="2px">
                    <CustomIcon color='text' SelectedIcon={Shopify} size="default" />
                </Box>}
                fill="horizontal"
                secondary
            />
            <Button
                event={{section: 'link_or_create_order', element: 'draft_invoice'}} 
                label="Draft invoice"
                fill="horizontal"
                secondary
                onClick={createOrder}
                icon={
                    <Box pad='2px'>
                        <FontAwesomeIcon SelectedIcon={IconOrderCreate} size="medium" />
                    </Box>}
            />
        </Box>
    );

    // return (<Box
    //     background="background"
    //     align="center"
    //     justify="start"
    //     direction="row"
    //     gap="small"
    //     margin={{top: 'small'}}
    //     pad={{left: '30px'}}
    // >
    //     <Box direction="row" pad={"small"} flex={{grow:1, shrink: 1}} justify="around" border={"between"} gap="small" align="center" width='100%'>
    //         <CardButton background='card' round='xsmall'>
    //             <Button border={{radius: 'xsmall'}} hoverIndicator={{dark: "dark-3", light: "light-1"}} onClick={() => {router.push('/settings/connections')}} round='xsmall'>
    //                 <Card width={{min: 'xsmall', max: 'small'}} height={{max: 'small'}} elevation="none" round='xsmall'>
    //                     <CardBody pad="small">
    //                         <Box margin='2px'>
    //                             <CustomIcon color='text' SelectedIcon={Shopify} size="default" />
    //                         </Box>
    //                         <Box margin={{bottom: 'small'}}>
    //                             <Text size="small" weight="bold">
    //                                     Link Shopify
    //                             </Text>
    //                         </Box>
    //                         {/* <Text size="small">Link your Shopify so we can sync and fetch your orders automatically</Text> */}
    //                     </CardBody>
    //                 </Card>
    //             </Button>
    //         </CardButton>
    //         <CardButton background='card' round='xsmall'>
    //             <Button primary hoverIndicator={{dark: "dark-3", light: "light-1"}} onClick={createOrder}>
    //                 <Card width={{min: 'xsmall', max: 'small'}} height={{max: 'small'}} elevation="none">
    //                     <CardBody pad="small" align="start">
    //                         <Box margin={{vertical: 'xsmall'}}>
    //                             <StreamlineIcon SelectedIcon={IconShoppingBasketAdd} size="medium" />
    //                         </Box>
    //                         <Box margin={{bottom: 'small'}} >
    //                             <Text size="small" weight="bold" >
    //                                 Create invoice
    //                             </Text>
    //                         </Box>
    //                         {/* <Text size="small">Create your first draft invoice in Streamlined and start invoicing</Text> */}
    //                     </CardBody>
    //                 </Card>
    //             </Button>
    //         </CardButton>
    //     </Box>
    // </Box>)
}

export default LinkOrCreateOrder;
