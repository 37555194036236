import React from "react";
import Link from "next/link";
import {Anchor} from "grommet";
import {useRouter} from "next/router";
import { pageEvent, trackClick } from "../utils/segment";
import Button from "./Button";

const NextAnchor = ({
    button, href, as, onClick, replace=false, event, scroll=true, ...rest
}) => {
    const router = useRouter();

    const handleClick = (e) => {
        const {target} = rest;
        onClick && onClick(e);
        e.preventDefault();
        if (!target && href) {
            if (href) {
                replace ? router.replace(href, as) : router.push(href, as, {scroll});
            }
        } else {
            if (href){
                pageEvent(href);
                window.open( href, target);
            }
        }
    };
    
    const showAnchor = () => (
        <Link 
            href={href} 
            scroll={scroll}
            {...(as && { as })} 
            legacyBehavior
            passHref>
            <Anchor onClick={() => event && trackClick(event)} {...rest} />
        </Link>
    );

    const showButton = () => (
        <Button
            event={event}
            {...rest} 
            {...{ onClick: handleClick }} />
    );

    return button ? showButton() : showAnchor();
};

export default NextAnchor;
