import React from "react";
import {Box, List, Meter, Text, Collapsible} from "grommet";
import Section from "../../Section";
import StatusIcon from "../../StatusIcon";
import ConditionalWrapper from "../../ConditionalWrapper";
import { isCompanyShopifyDemo, isGoodsMerchant, isServiceMerchant } from "../../../utils/permissions";
import LinkOrCreateOrder from "../../LinkOrCreateOrder";
import UpdateShopifyTemplate from "../../UpdateShopifyTemplate";
import {
    faChevronRight as IconChevronRight,
    faChevronDown as IconChevronDown,
    faPartyHorn as IconConfetti
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "../../FontAwesomeIcon";
import Button from "../../Button";
import { snakeCase } from "../../../utils/string";

const onboardingItems = [
    {
        headline: 'Personalize your invoices',
        getDescription: (company) => `Give your invoices the ${company?.business_name || 'personal'} touch`,
        href: '/settings/invoicing',
        key: 'has_logo'
    },
    {
        headline: 'Enable payment methods',
        description: 'Offer your customers payment options',
        href: '/settings/payment-and-payout',
        key: 'has_payment_method',
        hide: isCompanyShopifyDemo // conditionally show this onboarding item
    },
    {
        headline: 'Link your financial institution',
        description: 'Tell us where you’d like to receive payments',
        href: '/settings/payment-and-payout/payout',
        key: 'has_bank_account',
        hide: isCompanyShopifyDemo // conditionally show this onboarding item
    },
    {
        headline: 'Draft your first invoice',
        key: 'has_order_or_shopify',
        description: 'Create your invoice on Streamlined or link with Shopify to import orders',
        href: '/settings/connections',
        hide: isServiceMerchant,
        collapsible_content: () => <LinkOrCreateOrder/>
    },
    {
        headline: 'Configure Shopify for B2B',
        key: 'email_template_updated',
        description: `Update invoice email template`,
        hideOnShopifyNotLinked: true,
        collapsible_content: (setOpen) => <UpdateShopifyTemplate setOpen={setOpen}/>
    },
    {
        headline: 'Connect QuickBooks',
        key: 'has_quickbooks_linked',
        description: `Keep your books in sync`,
        hide: isGoodsMerchant,
        href: '/settings/connections',
    },
    {
        headline: 'Activate your Streamlined account',
        description: "Send your first invoice today",
        href: '/invoices',
        key: 'has_sent_invoice'
    }
];

export const mapOnboardingStatusToItem = (statuses, company) => {
    return (
        onboardingItems.map(
            item => ({
                ...item,
                completed: (item.key === "email_template_updated") ? (statuses['shopify_o_auths'].every(obj => obj[item.key] === true)) : (item.completed || statuses[item.key]),
                hide: (company && item?.hide && item.hide(company)) || (item?.hideOnShopifyNotLinked && !statuses.has_shopify_linked) // Workaround since company object gets cached
            })
        ).filter(
            item => !item.hide
        )
    );
}


const ActionItem = ({item, company}) => {
    const {headline, description, getDescription, completed, href, collapsible_content} = item;
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Box direction="row" gap="small" align="center" responsive={false} >
                <Box>
                    <StatusIcon type={completed ? 'ok' : 'not_completed'} />
                </Box>
                <ConditionalWrapper
                    condition={!completed}
                    wrapperIfTrue={
                        children =>(
                            <Button
                                event={{section: 'getting_started', element: 'action_item', description: snakeCase(headline)}}
                                fill="horizontal"
                                href={!collapsible_content?  href : undefined}
                                onClick={() => collapsible_content? setOpen(!open) : null}
                                hoverIndicator={!open}
                                round="small">
                                {children}
                            </Button>
                        )

                    }
                    wrapperIfFalse={
                        children =>
                            <>
                                {children}
                            </>
                    }>
                    <Box margin={{vertical: 'xxsmall'}} fill="horizontal">
                        <Box direction="row" align="center" justify="between" margin={{horizontal: 'small', vertical: "small"}} round="medium" gap="small">
                            <Box align="start">
                                <Text weight={500} textAlign="start">{headline}</Text>
                                { (description || getDescription) && !completed && (<Text weight="normal" textAlign="start">{getDescription? getDescription(company) : description}</Text>)}
                            </Box>
                            { !completed && !open && <FontAwesomeIcon SelectedIcon={IconChevronRight} size="small" /> }
                            { !completed && open && <FontAwesomeIcon SelectedIcon={IconChevronDown} size="small" /> }
                        </Box>
                    </Box>
                </ConditionalWrapper>
            </Box>
            { collapsible_content &&
            <Box>
                <Collapsible open={open}>
                    {collapsible_content(setOpen)}
                </Collapsible>
            </Box>
            }
        </>
    );
}

const GettingStarted = ({ items, company }) => {
    const progressPercentage = Math.round((items.filter(item => item.completed)).length / items.length  * 100);
    return (
        <Box
            round="xsmall"
            border={{round: 'small'}}
            pad={{horizontal: 'small', top: 'medium'}}
            elevation="medium"
            //  medium is too narrow while large too wide
            width={{ max: '600px' }}
            background="foreground">
            <Section
                margin="small"
                title="Get started with Streamlined"
                icon={IconConfetti}
                headerProps={{border: { size: 'none' }, pad: {horizontal: 'small'}}}>
                <Box fill="horizontal" margin={{top: 'xsmall'}}>
                    <Meter thickness="xxsmall" size="full" values={[{ value: progressPercentage, color: "status-ok", size: "full" }]} background="border"/>
                    <List
                        pad="xxsmall"
                        data={items}
                        margin={{top: 'small'}}>
                        {item => (<ActionItem company={company} item={item} />)}
                    </List>
                </Box>
            </Section>
        </Box>
    )
}

export default GettingStarted;
