export const formatCurrency = (minor_amount, currency = 'USD', currencySign="accounting") => (
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        currencySign
    }).format(minor_amount/100)
);

export const formatQuantity = (quantity) => {
    if (isNaN(quantity)) return 0;
    if (quantity % 1 == 0){ // If integer, render as integer
        return quantity | 0
    } else {
        return quantity || 0
    }
}

export const convertCentToDollar = (minor_amount) => minor_amount / 100;

export const convertBpsToPercent = (bps) => bps / 10000;

export const formatPercent = (num) => (
    new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
    }).format(num)
);

export const formatCurrencySI = (minor_amount, currency= 'USD') => {
    const major_amount = minor_amount/100;
    const SI = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "B" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = SI.length - 1; i > 0; i--) {
        if (major_amount >= SI[i].value) {
            break;
        }
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
    }).format((major_amount / SI[i].value)).replace(rx, "$1") + SI[i].symbol;
}

export const convertDollarToCent = amount => {
    if (isNaN(amount)) return 0;
    let v = 0;
    const decimal = '.';
    const precision = Math.pow(10, 2);

    if (typeof amount === 'number') {
        v = amount;
    } else if (typeof amount === 'string') {
        let regex = new RegExp('[^-\\d' + decimal + ']', 'g')
            , decimalString = new RegExp('\\' + decimal, 'g');
        v = amount
            .replace(/\((.*)\)/, '-$1')
            .replace(regex, '')            // replace any non numeric values
            .replace(decimalString, '.');  // convert any decimal values
        v = v || 0;
    } else {
        v = 0;
    }

    v *= precision;
    v = v.toFixed(4);

    return Math.round(v);
}