import React from "react";
import {Box, Text} from "grommet";
import FontAwesomeIcon from "./FontAwesomeIcon";
const Badge = ({ children, icon, textProps = {}, iconColor, ...rest }) => (
    <Box align="start">
        <Box
            justify="center"
            background={{ color: 'accent', opacity: 0.6}}
            pad={{ horizontal: 'xsmall' }}
            round="xxsmall"
            direction="row"
            align="center"
            gap="xsmall"
            {...rest}
        >
            {icon && (
                <FontAwesomeIcon SelectedIcon={icon} size="xsmall" {...(iconColor ? {color: iconColor} : {})} />
            )}
            <Text size="xsmall" weight={500} className="badge-text" {...textProps}>
                {typeof children === 'string' ? children.toUpperCase() : children}
            </Text>
        </Box>
    </Box>
);

export default Badge;
