import React from "react";
import { Text, ThemeContext, Box} from "grommet";
import {formatCurrency, formatCurrencySI } from "../utils/numbers";
import { normalizeColor } from "grommet/utils";
import { AGING_BALANCE_GRAPH_COLORS, AGING_BALANCE_GRAPH_COLOR_MAPPING } from "../constants/Colors";
import NextAnchor from "./NextAnchor";
import { PositionRelativeBox, PositionAbsoluteBox } from "../utils/styled";
import { PieChart, Pie, Cell , Tooltip} from "recharts";
import PercentBadge from "./PercentBadge";
import { useRouter } from "next/router";
import { formatDate, formatTime } from '../utils/dates';
import Button from "./Button";
import { mapRangeLabelToBERange } from "../utils/aging-balance-range";
import { snakeCase } from "../utils/string";

const AgingBalancesPieChart = ({ dashboardData, size, isLoading=true }) => {
    const {
        aging_balances: agingBalances,
        overdue_receivables: overDueAmount,
        total_receivables: totalAmount,
        time_calculated: timeCalculated,
        currency,
    } = dashboardData || {};

    const notYetDueAmount = agingBalances && agingBalances['0']?.amount;
    const data = totalAmount ? Object.values(agingBalances).map((aging_balance)=>{
        return({
            name: aging_balance.label,
            value: aging_balance.amount
        })
    }) : [{name: 'placeholder', value: 1}];
    const router = useRouter();

    const AgingBalanceLabel = ({agingBalance, color}) => {
        const label = (agingBalance.label == "Due") ? "Not yet due" : agingBalance.label;
        return (
            <Button
                event={{section: 'aging_balance_pie_chart', element: `breakdown_${snakeCase(label)}`}}
                hoverIndicator
                onClick={() => router.push(`/invoices/sent?overdue_range=${mapRangeLabelToBERange(label) || ''}`)}>
                <Box direction="row" justify="between" pad={{vertical: 'xsmall'}} gap="xxsmall"
                    skeleton={isLoading ? {animation: 'fadeIn'} : undefined }>
                    <Box direction="row" align="center" gap="xsmall">
                        <Box round="100%" background={color} width={{min: '10px'}} height={{min: '10px'}} />
                        <Text>{label}</Text>
                    </Box>
                    <Box>
                        <Text >{formatCurrency(agingBalance.amount)}</Text>
                    </Box>
                </Box>
            </Button>
        )
    };

    const AgingSummaryField = ({label, value, total}) => {
        return(
            <Box pad={{vertical: 'xsmall', horizontal: 'medium'}} gap="xsmall" skeleton={isLoading ? {animation: 'fadeIn'} : undefined }>
                <Box direction="row" align="center" gap="xsmall">
                    <Text>{label}</Text>
                </Box>
                <Box>
                    <Text size="xlarge" weight="bold">{formatCurrencySI(value)}</Text>
                    {!isLoading && <PercentBadge amount={value} total={total} />}
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box direction="row" border="bottom" margin={{bottom: "xsmall"}} justify="center">
                <Box {...(size==="small" ? {} : {direction: "row"})}>
                    <ThemeContext.Consumer>
                        { (theme) => {
                            return (
                                <PositionRelativeBox align="center">
                                    <PieChart width={220} height={220} animationDuration={3000}>
                                        <Pie
                                            data={data}
                                            cx={'50%'}
                                            cy={100}
                                            innerRadius={85}
                                            outerRadius={100}
                                            paddingAngle={3}
                                            dataKey="value"
                                            stroke="none">
                                            {data.map((entry, index) => {
                                                const color = AGING_BALANCE_GRAPH_COLOR_MAPPING[entry.name];
                                                return(<Cell
                                                    key={`cell-${index}`}
                                                    fill={normalizeColor(theme.global.colors[color] || color,theme)} />)
                                            })}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                    <PositionAbsoluteBox top={'80px'} right={0}>
                                        <Box align="center" fill="horizontal"
                                            skeleton={isLoading ? {animation: 'fadeIn'} : undefined }>
                                            <Text textAlign="center" whiteSpace='no-wrap'>Total receivables</Text>
                                            <Text textAlign="center" size="xlarge" weight="bold">
                                                {formatCurrencySI(totalAmount, currency)}
                                            </Text>
                                        </Box>
                                    </PositionAbsoluteBox>
                                </PositionRelativeBox>
                            );
                        }}
                    </ThemeContext.Consumer>
                    <Box {...(size==="small" ? {fill: "horizontal", align: "center", pad: {bottom: 'medium'}} : {})}>
                        <Box
                            direction={size==="small" ? "row" : "column"}
                            gap="small"
                            margin={{bottom: "medium"}}
                            {...(size==="small" ? {} : {justify: "between"})}>
                            <AgingSummaryField label={"Not yet due"} value={notYetDueAmount} total={totalAmount}/>
                            <AgingSummaryField label={"Overdue"} value={overDueAmount} total={totalAmount}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box border="between" gap="small" >
                {agingBalances && Object.values(agingBalances).map((agingBalance,index)=>{
                    return(<AgingBalanceLabel key={index} agingBalance={agingBalance} color={AGING_BALANCE_GRAPH_COLORS[index]}/>)
                })}
                <Box skeleton={isLoading ? {animation: 'fadeIn'} : undefined }>
                    {timeCalculated && <Text color='text-weak' size='small'>Last updated on {formatDate(timeCalculated)} {formatTime(timeCalculated)}</Text>}
                    <Box align="end" margin={{top: "small"}}>
                        <NextAnchor
                            label={
                                <Text direction="row" align="center">
                                    View more {" >"}
                                </Text>
                            }
                            href={"/reports/aging-balance"} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AgingBalancesPieChart;