import { Box, Form, FormField, Text, RadioButtonGroup, ResponsiveContext } from 'grommet';
import React, {useContext } from 'react';
import Modal from '../../Modal';
import { handleErrors } from '../../../utils/errors';
import {signIn, useSession} from "next-auth/react";
import { USER_ROLES } from '../../../constants/UserRoles';
import {
    faCircleCheck as IconCheck
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from '../../FontAwesomeIcon';
import Button from '../../Button';
const UserRoleAndTitleModal = ({
    user,
    onClose
}) => {
    const size = useContext(ResponsiveContext);
    const {data: session} = useSession();
    const {accessToken} = session || {};
    const refreshSession = () => {
        signIn('credentials', { token: accessToken, redirect: false });
    }
    const saveRoleAndTitle = (data) => {
        fetch("/api/account-details/profile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({id: user?.id,...data.value}),
        })
            .then(handleErrors).then(() => {
                onClose && onClose();
                refreshSession();
            })
    }
    return (
        <Modal
            ctaText="Ok"
            containerProps={{pad: {vertical: 'small', horizontal: 'medium'}}}
            sectionProps={{hideBorder: true, control: false}}
            contentProps={{width: 'auto', gap: 'small'}}
        >
            <Box gap="small" width={{max: "medium"}}>
                <Text weight='bold' size='xlarge'>Welcome to Streamlined!</Text>
                <Text wrap>To better personalize your experience, we have a couple of questions for you.</Text>
            </Box>
            <Box>
                <Form defaultValue={{role: user?.role, title: user?.title}} onSubmit={saveRoleAndTitle}>
                    <Box gap="small">
                        <FormField required name='role' label={<Text weight={450} size='medium'>What best describes your role? </Text>} contentProps={{border: false, pad: false, margin: false}}>
                            <RadioButtonGroup
                                direction="row"
                                name='role'
                                justify={size === "small" ? 'between' : undefined}
                                margin={{vertical: 'xsmall'}}
                                options={USER_ROLES}
                            >
                                {(option, {checked}) => {
                                    return (
                                        <Box
                                            round='xxsmall'
                                            pad='small'
                                            justify='center'
                                            align='center'
                                            border={{color: (checked ? 'status-ok' : 'border'), size: checked ? "small" : 'xsmall'}}
                                            direction="row"
                                            focusIndicator={false}
                                            gap="xsmall">
                                            { checked && (<FontAwesomeIcon SelectedIcon={IconCheck} size="small" color='status-ok'/>)}
                                            <Text textAlign='center' weight={checked ? 500 : 400}>{option.label}</Text>
                                        </Box>
                                    );
                                }}
                            </RadioButtonGroup>
                        </FormField>
                        <Box>
                            <FormField required defaultValue={user?.title} label={<Text weight={450} size='medium'>What is your title? </Text>} placeholder='E.g. Head of Operations' name='title'/>
                        </Box>
                        <Box align='end'>
                            <Button
                                event={{section: 'user_role_and_title_modal', element: 'submit'}}
                                primary 
                                label={<Box pad={{horizontal: 'small'}}><Text>Ok</Text></Box>} 
                                type='submit'/>
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Modal>
    );
}

export default UserRoleAndTitleModal;
